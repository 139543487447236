import React, {Component} from 'react';
import {Button, Divider, Form, Message, Segment} from "semantic-ui-react";
import {connect} from "react-redux";
import {OrgMenuBar} from "../../other/OrgMenuBar";
import lawyerAction from "../../../../../actions/lawyer.action";
import {orgState} from "../../../../../helper/stateUtil";
import Body from "../../../../other/Body";
import OrgSubHeader from "../../other/OrgSubHeader";
import QMCheckBox from "../../../../other/form/QMCheckBox";

class OrgAddLawyerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            status: true,
            client_id: null,
            client_name: "",
            user_id: null
        };
        this.onChange = this.onChange.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(lawyerAction.getClients({org_id: this.props.org.id}));
        this.props.dispatch(lawyerAction.getAddableLawyers({org_id: this.props.org.id}));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isDone) {
            this.props.history.go(-1);
        }
    }

    onSave(e) {
        e.preventDefault();
        this.props.dispatch(lawyerAction.create({
            org_id: this.props.org.id,
            name: this.state.name,
            email: this.state.email,
            status: this.state.status,
            client: this.state.client_id,
            client_name: this.state.client_name,
            user_id: this.state.user_id,
        }));
    }

    onChange(e) {
        this.setState({[e.target.name]: e.target.value});
    }

    render() {
        const {error, loading, clients, addable_lowers, org} = this.props;
        return (
            <Body loading={loading}>

                <OrgMenuBar/>

                <OrgSubHeader title={"Add Evaluatee"} backArrow/>

                <Segment basic padded='very'>
                    <Form>
                        <Message color='red' hidden={error.length === 0}>
                            <Message.List>
                                {error.map((item, index) => <Message.Item key={index}>{item}</Message.Item>)}
                            </Message.List>
                        </Message>

                        <br/>

                        <Form.Field>
                            <Form.Dropdown label='Select Exiting evaluatee:' width={8}
                                           selection search clearable
                                           onChange={(e, {value}) => this.setState({
                                               user_id: value,
                                               name: '',
                                               email: '',
                                               client_id: null,
                                               client_name: ''
                                           })}
                                           value={this.state.user_id}
                                           options={addable_lowers}/>
                        </Form.Field>

                        <br/>
                        <Divider horizontal>Or</Divider>
                        <br/>

                        <Form.Group>
                            <Form.Field width={8} disabled={!!this.state.user_id}>
                                <label>Evaluatee Name:</label>
                                <input placeholder='Evaluatee Name' onChange={this.onChange} type="text"
                                       name={"name"} value={this.state.name}/>
                            </Form.Field>
                            <Form.Field width={8} disabled={!!this.state.user_id}>
                                <label>Evaluatee Email:</label>
                                <input placeholder='Evaluatee Email' onChange={this.onChange} type="email"
                                       name={"email"} value={this.state.email}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={8}>
                                <Form.Dropdown label={`${org.str_client_firm}:`}
                                               selection disabled={!!this.state.user_id} clearable search
                                               onChange={(e, {value}) => this.setState({
                                                   client_id: value,
                                                   client_name: ''
                                               })}
                                               value={this.state.client_id}
                                               options={clients}/>
                            </Form.Field>
                            {this.state.client_id === 'new' &&
                            <Form.Field width={8}>
                                <label>{org.str_client_firm} Name: </label>
                                <input placeholder={`${org.str_client_firm} Name:`} onChange={this.onChange}
                                       name={"client_name"} value={this.state.client_name}/>
                            </Form.Field>}
                        </Form.Group>
                        <Form.Field>
                            <label>Status:</label>
                            <QMCheckBox toggle onChange={(checked) => this.setState({status: checked})}
                                        checked={this.state.status}/>
                        </Form.Field>
                        <br/>
                        <br/>
                        <Button type='submit' primary onClick={this.onSave} floated='right' circular
                                disabled={!((this.state.user_id) || (this.state.name && this.state.email && (!!(this.state.client_id || this.state.client_name))))}>Create</Button>
                        <Button type='button' secondary inverted floated='right' circular
                                onClick={() => this.props.history.go(-1)}>Cancel</Button>
                        <br/>
                    </Form>
                </Segment>
            </Body>
        );
    }
}

function mapStateToProps(state) {
    const s = orgState(state);
    return {
        ...s,
        ...state.lawyer
    };
}

const connectedOrgAddLawyerPage = connect(mapStateToProps)(OrgAddLawyerPage);
export {connectedOrgAddLawyerPage as OrgAddLawyerPage};

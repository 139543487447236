import lawyerService from "../services/lawyer.service";

// common
function request() {
    return {type: LAWYER.REQUEST};
}

function error(reason) {
    reason = reason.response;
    switch (reason.status) {
        case 403:
            return {type: LAWYER.NOT_ALLOWED};
        case 404:
            return {type: LAWYER.NOT_FOUND};
        case 422:
            return {type: LAWYER.VALIDATION_ERROR, payload: reason.data};
        default:
            return {type: LAWYER.ERROR, payload: reason.data};
    }
}

function create(data) {

    return dispatch => {
        dispatch(request());
        lawyerService.add(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };


    function success(res) {
        if (res.status === 201) {
            return {type: LAWYER.ADD_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function update(data) {
    return dispatch => {
        dispatch(request());
        lawyerService.update(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: LAWYER.UPDATE_SUCCESS, payload: res.data};
        }
        return error(res);
    }
}

function readAll(data) {
    return dispatch => {
        dispatch(request());
        lawyerService.readAll(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: LAWYER.READ_ALL_SUCCESS, payload: res.data};
    }
}

function readAllEvaluatees(data) {
    return dispatch => {
        dispatch(request());
        lawyerService.readAllEvaluatees(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: LAWYER.READ_ALL_SUCCESS, payload: res.data};
    }
}

function readAllEvaluateesEvals(data) {
    return dispatch => {
        dispatch(request());
        lawyerService.readAllEvaluateesEvals(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: LAWYER.READ_ALL_SUCCESS, payload: res.data};
    }
}

function read(data) {
    return dispatch => {
        dispatch(request());
        lawyerService.read(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: LAWYER.READ_SUCCESS, payload: res.data};
    }
}

function changeStatus(data) {
    return dispatch => {
        dispatch(request());
        lawyerService.changeStatus(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        if (res.status === 202) {
            return {type: LAWYER.CHANGE_STATUS_SUCCESS, payload: data};
        }
        return error(res);
    }
}

function getClients(data) {
    return dispatch => {
        dispatch(request());
        lawyerService.readClients(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: LAWYER.READ_ALL_CLIENTS_SUCCESS, payload: res.data};
    }
}

function getAddableLawyers(data) {
    return dispatch => {
        dispatch(request());
        lawyerService.getAddableLawyers(data).then(res => dispatch(success(res)), reason => dispatch(error(reason)));
    };

    function success(res) {
        return {type: LAWYER.READ_ALL_ADDABLE_LAWYERS_SUCCESS, payload: res.data};
    }
}

const lawyerAction = {
    create,
    update,
    readAll,
    read,
    changeStatus,
    getClients,
    getAddableLawyers,
    readAllEvaluatees,
    readAllEvaluateesEvals
};

// constants
export const LAWYER = {

    REQUEST: "LAWYER_REQUEST",
    ERROR: "LAWYER_ERROR",
    NOT_FOUND: "LAWYER_NOT_FOUND",
    VALIDATION_ERROR: "LAWYER_VALIDATION_ERROR ",
    NOT_ALLOWED: "LAWYER_NOT_ALLOWED",

    ADD_SUCCESS: "LAWYER_ADD_SUCCESS",
    UPDATE_SUCCESS: "LAWYER_UPDATE_SUCCESS",
    READ_ALL_SUCCESS: "LAWYER_READ_ALL_SUCCESS",
    READ_SUCCESS: "LAWYER_READ_SUCCESS",
    CHANGE_STATUS_SUCCESS: "LAWYER_CHANGE_STATUS_SUCCESS",
    READ_ALL_CLIENTS_SUCCESS: "LAWYER_READ_ALL_CLIENTS_SUCCESS",
    READ_ALL_ADDABLE_LAWYERS_SUCCESS: "LAWYER_READ_ALL_ADDABLE_LAWYERS_SUCCESS",

};

export default lawyerAction;

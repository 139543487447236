import api from "../helper/api";

function add(data) {
    return api.instance().post("org/lawyer/store", data);
}

function update(data) {
    data._method = "PUT";
    return api.instance().put("org/lawyer/update", data);
}

function readAll(data) {
    return api.instance().post("org/lawyer/get-all", data);
}

function readAllEvaluatees(data) {
    return api.instance().post("org/lawyer-evaluations/lawyers", data);
}

function readAllEvaluateesEvals(data) {
    return api.instance().post("org/lawyer-evaluations/list", data);
}

function read(data) {
    return api.instance().post("org/lawyer/get", data);
}

function changeStatus(data) {
    return api.instance().put("org/lawyer/change-status", data);
}

function readClients(data) {
    return api.instance().post("org/lawyer/get-all-clients", data);
}

function getAddableLawyers(data) {
    return api.instance().post("org/lawyer/search", data);
}

const lawyerService = {
    add,
    update,
    readAll,
    read,
    changeStatus,
    readClients,
    readAllEvaluatees,
    readAllEvaluateesEvals,
    getAddableLawyers
};

export default lawyerService;
